import axios from 'axios'
import { VueAxios } from './axios'
import Vue from 'vue'
import store from '@/store'
import CONFIG from '@/config'
import { getSign } from '@/utils/common'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: CONFIG.baseURL,
  timeout: 60000 // 请求超时时间
})

let logOutBol = false

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    console.log(error)
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = store.getters.token
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  config.headers.platform = 'WEB'
  if (token) {
    config.headers['access-token'] = token
  }
  if (config.headers['Content-Type'] !== 'multipart/form-data') {
    config.params = { ...config.params, sign: getSign(config.params) }
    config.data = { ...config.data, sign: getSign(config.data) }
  }

  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if (response.status === 200) {
    if (response.data.status !== 200) {
      if (response.data.status === 401) {
        store.dispatch('LogOut', {})
      } else {
        logOutBol = false
      }
      if (!logOutBol) {
        Vue.prototype.$message.error(response.data.message)
      }
      logOutBol = true
      return Promise.reject(response.data)
    } else {
      logOutBol = false
      return response.data
    }
  }
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
