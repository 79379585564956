import request from '@/utils/request'

const jobApi = {
  getDetails: 'jobPosition/getDetails',
  shareConfig: 'share/shareConfig',
  collect: 'jobPosition/collect',
  enroll: 'jobPosition/enroll',
  getSelectedJobId: 'jobPosition/getSelectedJobId',
  getRealExamList: 'exam/getRealExamList',
  setSelectedJobId: 'jobPosition/setSelectedJobId',

  courseList: 'course.course/list',
  providerList: 'provider/list'
}

// 课程列表
export function courseList(parameter) {
  return request({
    url: jobApi.courseList,
    method: 'get',
    params: parameter
  })
}

// 机构列表
export function providerList(parameter) {
  return request({
    url: jobApi.providerList,
    method: 'get',
    params: parameter
  })
}
export function setSelectedJobId(parameter) {
  return request({
    url: jobApi.setSelectedJobId,
    method: 'get',
    params: parameter
  })
}

export function getRealExamList(parameter) {
  return request({
    url: jobApi.getRealExamList,
    method: 'get',
    params: parameter
  })
}

export function getSelectedJobId(parameter) {
  return request({
    url: jobApi.getSelectedJobId,
    method: 'get',
    params: parameter
  })
}

export function enroll(parameter) {
  return request({
    url: jobApi.enroll,
    method: 'post',
    data: parameter
  })
}

export function collect(parameter) {
  return request({
    url: jobApi.collect,
    method: 'post',
    data: parameter
  })
}

export function shareConfig(parameter) {
  return request({
    url: jobApi.shareConfig,
    method: 'get',
    params: parameter
  })
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function getDetails(parameter) {
  return request({
    url: jobApi.getDetails,
    method: 'get',
    params: parameter
  })
}
