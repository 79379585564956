import Vue from 'vue'
import App from './App.vue'
import router from './router'
import echarts from 'echarts'
import store from './store'
import {
  Button, Carousel, CarouselItem, Message, Popover, MessageBox, Dialog, Breadcrumb, BreadcrumbItem, Select,
  Option, Table, DropdownMenu, DropdownItem, TableColumn, Form, FormItem, Input, Pagination, Dropdown, Cascader,
  Col, Progress, Image, Collapse, CollapseItem, Tree, Checkbox
} from 'element-ui'
import './assets/theme/index.css'
import storage from '@/utils/storage'// 引入

import Directive from './directives' // 自定义指令
Vue.use(Directive)

Vue.prototype.$storage = storage// 加入vue的原型对象
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(Tree)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popover)
Vue.use(Dialog)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(FormItem)
Vue.use(Form)
Vue.use(Input)
Vue.use(Pagination)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Option)
Vue.use(Select)
Vue.use(Cascader)
Vue.use(Col)
Vue.use(Progress)
Vue.use(Image)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.config.productionTip = false

Vue.prototype.$echarts = echarts
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
