<template>
  <div id="app" :class="{ bg }">
    <pageHeader ref="pageHeader" v-if="!$route.meta.isShow" />
    <div class="user-bg" v-if="isUser">
      <img class="bg-icon" src="@/assets/img/user-bg@2x.png" alt="">
      <div class="user-bg-info">
        <div class="name">
          <p class="text">个人中心</p>
          <a class="exit" @click="$refs.pageHeader.logOut()" href="javascript:;">
            <img src="@/assets/img/rollback@2x.png" alt="">
            <span>退出登录</span>
          </a>
        </div>
        <p class="tips">欢迎来到个人中心！</p>
      </div>
    </div>
    <div class="page-wrap" v-if="!$route.meta.isShow">
      <router-view />
    </div>
    <div class="page" v-else>
      <router-view />
    </div>
    <footer v-if="!$route.meta.isShow">
      <div class="footer">
        <div class="info">
          <a href="">
            <img src="@/assets/img/LOGO@2x-1.png" class="logo" alt="" />
          </a>
          <p class="company">中智国培科技发展(北京)有限公司</p>
          <a href="http://beian.miit.gov.cn/" target="_blank" class="number">京ICP备2023029662号-3</a>
        </div>
        <div class="about">
          <div class="text">关于我们</div>
          <p>
            中智国培科技发展（北京）有限公司是中智集团以“政企培训”为核心业务的全资子公司，成立24年以来，共培训央国企干部职工共计46万人次，其中央国企中高级管理者6万人次。自2005年开始深入国务院国资委国企改革系列行动，至今组织1000+家央企法人单位、超过5000+一级及二级央企高层管理人员进行对标世界一流体系化学习。+家央企法人单位、超过5000+一级及二级央企高层管理人员进行对标世界一流体系化学习。
          </p>
        </div>
        <div class="eail">
          <div class="text">联系邮箱</div>
          <div class="num">zhangying@ciicc.com</div>
          <div class="art">
            <router-link to="/newsInfo?articleId=2" class="item">服务协议</router-link>
            <router-link to="/newsInfo?articleId=3" class="item">隐私协议</router-link>
          </div>
        </div>
        <!-- <div class="customer">
          <div class="download">
            <a href="">App Store 下载</a>
            <a href="">Android 下载</a>
          </div>
          <div class="service">
            <p>官方客服：</p>
            <img src="@/assets/image/code.png" class="item" alt="" />
            <img src="@/assets/image/code.png" alt="" />
          </div>
        </div> -->
      </div>
    </footer>
  </div>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import router from './router'

export default {
  components: {
    pageHeader
  },
  data () {
    return {
      bg: false,
      isUser: false
    }
  },
  created () {
    router.afterEach((to, from) => {
      this.isUser = to.matched[0].path === '/my'
      if (to.path === '/examination/imitate'  || to.path === '/examination/contrast' || to.path === '/') {
        this.bg = true
      } else {
        this.bg = false
      }
    })
  }
}
</script>

<style lang="less">
@import "./assets/css/reset.less";

html,
body {
  height: 100%;
  margin: 0;
  background-color: #ffffff;
  min-width: 1200px;
  color: #030303;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &.bg {
    background-color: #f7f8fc;
  }
}

/*设置宽度,轨道颜色*/
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background: #e6e6e6;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: #0675f8;
  border-radius: 10px;
}

.line-clamp-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-clamp-3 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.noData {
  padding-top: 75px;
  color: #5e6272;
  text-align: center;

  .emptyimg {
    width: 175px;
  }

  .empty-text {
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    margin-top: 2px;
  }
}

.user-bg {
  position: relative;
  padding-top: 52px;
  .bg-icon {
    width: 100%;
  }
  .user-bg-info {
    width: 1200px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 110px;
    .name {
      display: flex;
      align-items: center;
      .text {
        font-size: 40px;
        line-height: 55px;
        font-weight: 500;
        color: #ffffff;
      }
      .exit {
        margin-left: auto;
        width: 135px;
        height: 44px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 16px;
          margin-right: 8px;
        }
      }
    }
    .tips {
      color: #ffffff;
      font-size: 16px;
      line-height: 28px;
      margin-top: 16px;
    }
  }
}

.page-wrap {
  width: 1200px;
  margin: 0 auto;
  padding-top: 52px;
  padding-bottom: 30px;
  min-height: calc(100vh - 180px);
  box-sizing: border-box;
}

footer {
  height: 180px;
  background: #56595f;
  box-sizing: border-box;
  padding-top: 30px;
  z-index: 999;

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    margin: auto;
    color: #fff;

    .info {
      .logo {
        width: 56px;
        height: 34px;
      }

      .number {
        margin-top: 13px;
        font-size: 12px;
        font-weight: 400;
        color: #ececec;
        display: block;
      }

      .company {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        margin-top: 13px;
      }
    }

    .about {
      flex: 1;
      margin: 0 57px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;

      .text {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 27px;
      }
    }

    .eail {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;

      .text {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 27px;
      }
      .art {
        display: flex;
        margin-top: 14px;
        .item {
          font-size: 14px;
          color: #ffffff;
          margin-right: 10px;
        }
      }
    }

    // .customer {
    //   margin-left: auto;

    //   .download {
    //     display: flex;

    //     a {
    //       height: 30px;
    //       background: #000000;
    //       border-radius: 4px;
    //       border: 1px solid #9d9d9d;
    //       padding: 0 10px 0 32px;
    //       display: flex;
    //       align-items: center;
    //       color: #ffffff;
    //       font-size: 12px;
    //       font-weight: 600;
    //       margin-left: 10px;
    //       background-repeat: no-repeat;
    //       background-size: 20px;
    //       background-position: left 5px center;
    //       background-image: url("@/assets/image/apple-icon@2x.png");

    //       &:first-child {
    //         margin-left: 0;
    //         background-image: url("@/assets/image/Android-icon@2x.png");
    //       }
    //     }
    //   }

    //   .service {
    //     display: flex;
    //     justify-content: flex-end;
    //     font-weight: 600;
    //     color: #ffffff;
    //     font-size: 14px;
    //     line-height: 22px;
    //     margin-top: 30px;

    //     img {
    //       width: 70px;
    //       margin-left: 10px;

    //       &.item {
    //         margin-left: 33px;
    //       }
    //     }
    //   }
    // }
  }
}

.el-button--primary {
  background: #0675f8 !important;
  border-color: #0675f8 !important;
}</style>
